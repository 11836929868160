import { RemixBrowser } from '@remix-run/react';
import i18next from 'i18next';
import { startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { z } from 'zod';
import { zodI18nMap } from '~/features/i18n/errors';

import { initializeI18nClient } from '~/features/i18n/i18n.client';
import { SentryInit } from '~/features/sentry/sentry.client';

startTransition(() => {
  SentryInit();
  initializeI18nClient().then(() => {
    z.setErrorMap(zodI18nMap);
    hydrateRoot(
      document,
      <I18nextProvider i18n={i18next}>
        <RemixBrowser />
      </I18nextProvider>
    );
  });
});
