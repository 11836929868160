import type * as Sentry from '@sentry/remix';
import { isDevelopment } from '~/utils/is-development';

import { sentryDsn } from '~/utils/sentry-dsn';

const tracesSampleRate = 0.1;
const replaysSessionSampleRate = 0.1;
const replaysOnErrorSampleRate = 1.0;

type SentrySettings = Parameters<typeof Sentry.init>[0];
export const settings: SentrySettings = {
  dsn: sentryDsn,
  beforeSendSpan: span => {
    if (isDevelopment()) {
      console.warn('Dropping span in development', span);
      return null;
    }
    return span;
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate,
  replaysSessionSampleRate,
  replaysOnErrorSampleRate,
};
