import { useLocation, useMatches } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { useEffect } from 'react';

import { settings } from './sentry';

const replaysSessionSampleRate = 0.1;
const replaysOnErrorSampleRate = 1.0;

export const SentryInit = () => {
  return Sentry.init({
    ...settings,
    tunnel: '/tunnel',
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tracePropagationTargets: ['localhost', /^https:\/\/invoicify\.theyoxy\.be/, /^https:\/\/invoicify\.endevops\.be/],
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
  });
};
